/* As soon we decide tha we want scrollToContentModule to work for any routes and for every contentModule ids
 we would refactor line 31 to check if any route contains any content module id and then get rid of `contentModuleWithHashes` const
 */
export const contentModuleWithHashes = [
  'lp-home-theater-cinematic-sound',
  'lp-home-theater-hear-every-word',
  'lp-home-theater-play-music-scroll-sequence',
  'lp-home-theater-find-the-right-soundbar',
  'lp-home-theater-control-your-way',
  'lp-home-theater-customize-the-sound',
  'lp-home-theater-optimize-the-sound',
  'lp-home-theater-build-the-system-carousel-mobile',
  'lp-home-theater-build-the-system',
  'lp-home-theater-sets-carousel',
  'lp-home-theater-ultimate-guide',
]
