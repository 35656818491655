/* eslint-disable no-underscore-dangle */
import { Query } from '@sonos-inc/web-lib-appsync'
import { SanitySEO } from '@sonos-inc/web-lib-types'
import { ContentPage, FootnotesFactory, SEO } from '@sonos-inc/www-components'
import {
  getContentPids,
  useFootnotes,
  UserContentProvider,
} from '@sonos-inc/www-contexts'
import { useRouteChange } from '@sonos-inc/www-hooks'
import {
  customerGroupsQuery,
  enhancedProductCompareQuery,
  fallbackLocaleQuery,
  productCarouselQuery,
  productTile,
} from '@sonos-inc/www-queries'
import {
  getPageRevalidateConfig,
  resolveLocale,
  resolveLocaleRedirect,
} from '@sonos-inc/www-routing-helpers'
import {
  fetchData,
  fetchHeaderSubcategories,
  fetchPageBySlug,
  fetchPageData,
  fetchSplashPage,
  getClient,
  logger,
  resolvePreviewDate,
} from '@sonos-inc/www-server'
import { ContentPageProps } from '@sonos-inc/www-types'
import { GetStaticPaths, GetStaticProps, InferGetStaticPropsType } from 'next'
import React from 'react'

import { contentModuleWithHashes } from '../appHelpers/contentModuleWithHashes'

function Page({
  locale,
  fallbackLocale,
  page,
  slug,
  svgs,
  microcopy,
  bonusProductData,
  footnotes,
  ...props
}: InferGetStaticPropsType<typeof getStaticProps>) {
  useRouteChange(handleRouteChange)

  const { setFootnotes } = useFootnotes()

  React.useEffect(() => {
    setFootnotes(footnotes)
  }, [footnotes, setFootnotes])

  function handleRouteChange(url: any) {
    if (url.includes('products/wireless-home-theater#')) {
      const contentModuleIDFromUrl = url.split('#')[1]
      // check if contentModuleID is contained within the list of contentModule ID that should have the scroll functionality
      if (contentModuleWithHashes.includes(contentModuleIDFromUrl)) {
        // find DOM element with the id from URL
        const anchor = document.querySelector(`${contentModuleIDFromUrl}`)
        // scroll to it
        anchor?.scrollIntoView({ behavior: 'smooth', block: 'nearest' })
      }
    }
  }

  const allPids = Array.from(getContentPids(page?.content))

  return (
    <>
      {(page?.seo || page?.scheduledSeo) && (
        <SEO
          {...(page.seo as SanitySEO)}
          slug={slug}
          scheduledSeo={page.scheduledSeo}
        />
      )}
      <UserContentProvider pids={allPids}>
        <ContentPage
          id="main"
          key={page?.['_key']}
          {...page}
          bonusProductData={bonusProductData}
        />
      </UserContentProvider>
    </>
  )
}

async function getBonusProductData(
  pageResults: {
    page: ContentPageProps | null
  },
  locale: string
): Promise<{ [key: string]: any }> {
  const bonusProductData: { [key: string]: any } = {}

  const allGridPids = new Set<string>()
  const allCarouselPids = new Set<string>()
  const carouselPidsMap: { [key: string]: string[] } = {}
  const enhancedProductComparePids = new Set<string>()

  //Determine extra product data
  pageResults.page?.content?.forEach((content) => {
    if (content?._type === 'productTileGrid') {
      content.products?.forEach((p: any) => {
        if (p.externalId) {
          allGridPids.add(p.externalId)
        }
      })
    }
    if (content?._type === 'productCarousel' && content._id) {
      carouselPidsMap[content._id] = []
      content.products
        ?.filter((product: any) => product._type === 'product')
        .forEach((p: any) => {
          if (p.externalId) {
            allCarouselPids.add(p.externalId)
            carouselPidsMap[content._id!].push(p.externalId)
          }
        })
    }
    if (content?._type === 'productCompare') {
      content.productList?.forEach((product) => {
        enhancedProductComparePids.add(product.externalId)
      })
    }
  })

  const [
    productTileGridResponse,
    productCarouselResponse,
    enhancedProductCompareResponse,
  ] = await Promise.all([
    fetchData<Query>(productTile, {
      locale,
      pids: Array.from(allGridPids).join(','),
    }),
    fetchData<Query>(productCarouselQuery, {
      locale,
      pids: Array.from(allCarouselPids).join(','),
    }),
    fetchData<Query>(enhancedProductCompareQuery, {
      locale,
      pids: Array.from(enhancedProductComparePids).join(','),
    }),
  ])

  bonusProductData['productTileGrid'] = productTileGridResponse
  bonusProductData['productCarousel'] = [
    productCarouselResponse,
    carouselPidsMap,
  ]
  bonusProductData['productCompare'] = enhancedProductCompareResponse

  return bonusProductData
}

export const getStaticProps: GetStaticProps = async ({
  params = {},
  locale: nextLocale,
  previewData,
}) => {
  const { slug } = params

  const previewDate = resolvePreviewDate(previewData)

  const localeRedirect = resolveLocaleRedirect(nextLocale, slug)
  if (localeRedirect) return localeRedirect

  const locale = resolveLocale(nextLocale, '')

  if (!locale || !slug)
    return { notFound: true } as { notFound: true; props?: null }

  const client = getClient(false)
  const parsedSlug = Array.isArray(slug) ? slug.join('/') : slug

  const fallbackLocale = await client.fetch<string>(fallbackLocaleQuery, {
    locale,
  })

  let props: Record<string, any> = {}

  try {
    const personalizationData = await fetchData<Query>(customerGroupsQuery, {
      locale,
    })

    const navHeaderSubCategoryData = await fetchHeaderSubcategories(locale)

    const customerGroups = (personalizationData?.getPersonalizations
      .userPersonalizations?.customerGroups ?? []) as Array<string>
    const [
      pageResults,
      {
        currentLocale,
        forms,
        microcopy,
        dtcValueProps,
        svgs,
        header,
        footer,
        ribbon,
      },
      splashPage,
    ] = await Promise.all([
      fetchPageBySlug(locale, parsedSlug, fallbackLocale, previewDate),
      fetchPageData(
        client,
        locale,
        [
          'icon-arrow-next.svg',
          'icon-arrow-previous.svg',
          'icon-checkmark.svg',
          'icon-left-chevron-arrow.svg',
          'icon-left-chevron-arrow-mobile.svg',
          'icon-right-chevron-arrow.svg',
          'icon-right-chevron-arrow-mobile.svg',
          'icon-speaker.svg',
          'icon-audio-white.svg',
          'icon-audio-disabled-white.svg',
          'icon-external-link.svg',
          'icon-close.svg',
          'icon-search.svg',
          'icon-loader.svg',
          'avatar-svc.svg',
          'icon-compare.svg',
        ],
        ['www'],
        ['search'],
        parsedSlug,
        customerGroups,
        fallbackLocale,
        previewDate
      ),
      fetchSplashPage(
        client,
        locale,
        fallbackLocale,
        process.env.SPLASH_PAGE_ID
      ),
    ])

    if (!pageResults.page)
      return { notFound: true } as { notFound: true; props?: null }

    const bonusProductData = await getBonusProductData(pageResults, locale)

    const footnotesFactory = new FootnotesFactory(previewDate)
    const footnotes = footnotesFactory
      .withCustomSource(pageResults.page, bonusProductData)
      .search()
    const footnotesJumpLinkEnabled = pageResults.page.footnotesJumpLinkEnabled

    props = {
      slug: parsedSlug,
      locale,
      fallbackLocale,
      page: pageResults.page,
      svgs,
      microcopy,
      dtcValueProps,
      currentLocale,
      forms,
      header: pageResults.page.headerOverride ?? header,
      stickySubnav: pageResults.page.stickySubnav,
      footer,
      ribbon,
      previewDate,
      navHeaderSubCategoryData,
      bonusProductData,
      splashPage,
      shouldOverlayHeader:
        pageResults.page.content &&
        pageResults.page.content[0] &&
        pageResults.page.content[0]._type &&
        pageResults.page.content[0]._type === 'hero',
      footnotesJumpLinkEnabled,
      footnotes,
    }
  } catch (error) {
    logger.error(error, 'getStaticProps error')
    throw new Error(`Error generating static props: ${error}`)
  }

  return {
    props,
    notFound: !props.page,
    revalidate: getPageRevalidateConfig(props.navHeaderSubCategoryData),
  }
}

export const getStaticPaths: GetStaticPaths = async () => {
  return {
    fallback: 'blocking',
    paths: [],
  }
}

export default Page
